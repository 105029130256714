

import {Component} from 'vue-property-decorator';
import PageRequest from '@/store/entities/page-request'
import AbpBase from "@/lib/abpbase";
import OverviewForm from './job-overview-data.vue'

@Component({
  components: {OverviewForm}
})
export default class JovOverviewList extends AbpBase {
  edit(id) {
    this.$store.commit('jobOverview/edit', id);
    this.editModalShow = true;
  }

  getButtonType(isNotFilled, isFillnessRequired){
    if (isFillnessRequired){
      return 'error'
    }
    if (isNotFilled){
      return 'warning'
    }
    return 'success'
  }

  pagerequest: PageRequest = new PageRequest();

  createModalShow: boolean = false;
  editModalShow: boolean = false;

  get list() {
    let data = this.$store.state.jobOverview.list;

    data.forEach(x => {
      x.cellClassName = {
        hbl: this.rowClassName(x.hblDocumentAvailability, x.hblDocumentMustBePresented),
        hawb: this.rowClassName(x.hawbDocumentAvailability, x.hawbDocumentMustBePresented),
        mbl: this.rowClassName(x.mblDocumentAvailability, x.mblDocumentMustBePresented),
        obl: this.rowClassName(x.oblDocumentAvailability, x.oblDocumentMustBePresented),
        mawb: this.rowClassName(x.mawbDocumentAvailability, x.mawbDocumentMustBePresented),
        civ: this.rowClassName(x.civDocumentAvailability, x.civDocumentMustBePresented),
        coo: this.rowClassName(x.cooDocumentAvailability, x.cooDocumentMustBePresented),
        pkd: this.rowClassName(x.pkdDocumentAvailability, x.pkdDocumentMustBePresented),
        customsEntry: this.rowClassName(x.customsEntryDocumentAvailability, x.customsEntryDocumentMustBePresented),
        dsb: this.rowClassName(x.dsbDocumentAvailability, x.dsbDocumentMustBePresented),
        inv: this.rowClassName(x.invCDocumentAvailability, x.invCDocumentMustBePresented),
        invF: this.rowClassName(x.invFDocumentAvailability, x.invFDocumentMustBePresented),
        ca: this.rowClassName(x.caDocumentAvailability, x.caMustBePresented),
        departure: this.rowClassName(x.departureActionAvailability, x.departureActionMustBePresented),
        caSent: this.rowClassName(x.caSentActionAvailability, x.caSentMustBePresented),
        caIn: this.rowClassName(x.caInActionAvailability, x.caInMustBePresented),
        sentCci: this.rowClassName(x.sentCciActionAvailability, x.sentCciMustBePresented),
        sentCce: this.rowClassName(x.sentCceActionAvailability, x.sentCceMustBePresented),
        payShippingLine: this.rowClassName(x.payShippingLineActionAvailability, x.payShippingLineMustBePresented),
        deliveryRequestToTransport: this.rowClassName(x.deliveryRequestToTransportActionAvailability, x.deliveryRequestToTransportMustBePresented),
        cartageRequestToTransport: this.rowClassName(x.cartageRequestToTransportActionAvailability, x.cartageRequestToTransportMustBePresented),
        arrivalNotice: this.rowClassName(x.arrivalNoticeActionAvailability, x.arrivalNoticeMustBePresented),
      };
    });
    return data;
  };

  async search() {
    this.$store.commit('jobOverview/setCurrentPage', 1);
    await this.getPage();
  }

  get loading() {
    return this.$store.state.jobOverview.loading;
  }

  async showJob(currentRow) {
    if (!this.checkPermissions('Pages.EditJob')) {
      await this.$router.push({name: 'job-client-form', params: {jobId: currentRow.id.toString()}});
    } else {
      await this.$router.push({name: 'job-form', params: {jobId: currentRow.id.toString()}});
    }
    await this.getPage();
  }

  checkPermissions(permission: string) {
    return window.abp.auth.hasPermission(permission);
  }

  pageChange(page: number) {
    this.$store.commit('jobOverview/setCurrentPage', page);
    this.getPage();
  }

  pageSizeChange(pageSize: number) {
    this.$store.commit('jobOverview/setPageSize', pageSize);
    this.getPage();
  }

  async getPage() {
    this.pagerequest.maxResultCount = this.pageSize;
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

    await this.$store.dispatch({
      type: 'jobOverview/getAll',
      data: this.pagerequest
    })
  }

  get pageSize() {
    return this.$store.state.jobOverview.pageSize;
  }

  get totalCount() {
    return this.$store.state.jobOverview.totalCount;
  }

  get currentPage() {
    return this.$store.state.jobOverview.currentPage;
  }

  columns = [{
    title: this.L('Job'),
    width: 100,
    key: 'id'
  }, {
    title: this.L('Consol'),
    key: 'consolidationNumber',
    width: 100
  }, {
    title: this.L('Client'),
    key: 'client',
    width: 200
  }, {
    title: this.L('Documents'),
    align: 'center',
    children: [
      {
        title: this.L('HBL'),
        key: 'hbl',
        width: 45,
        className: 'toptobottom'
      },
      {
        title: this.L('MAWB'),
        key: 'mawb',
        width: 45,
        className: 'toptobottom'
      },
      {
        title: this.L('OBL'),
        key: 'obl',
       width: 45,
        className: 'toptobottom'
      },
      {
        title: this.L('HAWB'),
        key: 'hawb',
        width: 45,
        className: 'toptobottom'
      },
      {
        title: this.L('MBL'),
        key: 'mbl',
        width: 45,
        className: 'toptobottom'
      },
      {
        title: this.L('CIV'),
        key: 'civ',
        width: 45,
        className: 'toptobottom'
      },
      {
        title: this.L('COO'),
        key: 'coo',
        width: 45,
        className: 'toptobottom'
      },
      {
        title: this.L('PKD'),
        key: 'pkd',
       width: 45,
        className: 'toptobottom'
      },
      {
        title: this.L('CUSTOMS ENTRY'),
        key: 'customsEntry',
        width: 45,
        className: 'toptobottom'
      },
      {
        title: this.L('DSB C'),
        key: 'dsb',
        width: 45,
        className: 'toptobottom'
      },
      {
        title: this.L('INV C'),
        key: 'inv',
        width: 45,
        className: 'toptobottom'
      },
      {
        title: this.L('CA'),
        key: 'ca',
        width: 45,
        className: 'toptobottom'
      },
    ]
  }, {
    title: this.L('Actions'),
    align: 'center',
    children: [
      {
        title: this.L('DEPARTURE'),
        key: 'departure',
        width: 45,
        className: 'toptobottom'
      },
      {
        title: this.L('ARRIVAL N'),
        key: 'arrivalNotice',
        width: 45,
        className: 'toptobottom'
      },
      {
        title: this.L('CA SENT'),
        key: 'caSent',
       width: 45,
        className: 'toptobottom'
      },
      {
        title: this.L('CA IN'),
        key: 'caIn',
        width: 45,
        className: 'toptobottom'
      },
      {
        title: this.L('SENT CCI'),
        key: 'sentCci',
        width: 45,
        className: 'toptobottom'
      },
      {
        title: this.L('SENT CCE'),
        key: 'sentCce',
        width: 45,
        className: 'toptobottom'
      },
      {
        title: this.L('PAY SHIPPING LINE'),
        key: 'payShippingLine',
        width: 45,
        className: 'toptobottom'
      },
      {
        title: this.L('DERTT'),
        key: 'deliveryRequestToTransport',
        width: 45,
        className: 'toptobottom'
      },
      {
        title: this.L('CARTT'),
        key: 'cartageRequestToTransport',
        width: 45,
        className: 'toptobottom'
      }
    ]
  },
    {
      title: this.L('Fields'),
      slot: 'fields',
      width: 200,
      align: 'center'
    }
  ]

  async created() {
    await this.getPage();
  }

  rowClassName(isAttached, isRequired) {
    if (isAttached) {
      return 'demo-table-info-cell-hasInput';
    } else if (isRequired) {
      return 'demo-table-info-cell-hasNothingAndRequired';
    }
    return 'demo-table-info-cell-hasNothingAndNotRequired';
  }
}
